/* @import '~@bootstrap/dist/css/bootstrap.min.css'; */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Toastify__toast.Toastify__toast--success{
  font-size: 1.3rem !important;
}

thead>tr>th {
  font-size: 1.5rem;
  font-weight: bolder !important;
}

tr.Polaris-DataTable__TableRow {
  font-size: 1.5rem;
  border-bottom: 1.5px solid #f4f6f8 !important;
  text-align: center !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calendar-slot-booker.esRawo {
  width: 50%;
  height: auto;
  margin: 0 auto;
  margin-top: 5%;
}

.Polaris-DatePicker__Title, .Polaris-DatePicker__Weekday, button.Polaris-DatePicker__Day.Polaris-DatePicker__Day--disabled{
  font-size: initial;
}

h2.Polaris-Heading {
  margin-top: 40px;
  font-size: large;
  color: indianred !important;
}

button#indianred {
  background: #d96c80!important;
  color: white;
  float: right;
}