@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
/* @import "~@blueprintjs/select/lib/css/blueprint-select.css"; */
/* @import "~@blueprintjs/table/lib/css/table.css"; */
/* @import "~@blueprintjs/timezone/lib/css/blueprint-timezone.css"; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bp3-transition-container {
  z-index: 1020 !important;
}

input.form-control.my-3 {
  padding: 2rem;
  font-size: 1.5rem;
}

.Polaris-Modal__Spinner svg {
  width: 4.4rem;
  animation: Polaris-Spinner--loading .5s linear infinite;
}

.chatbox-container {
  height: calc(100vh - 120px);
  width: 100%
}
/* .pagination.custom {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  margin: 20px 0;
  border-radius: 4px;
  float: right;
} 
 .pagination li {
  display: inline-block;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 10px 20px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 500;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}
.center {
  margin: auto;
  width: 50%;
  max-width: 400px;
  min-width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
div.loader {
  font-size: 100px;
}

.loaded {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
} */
